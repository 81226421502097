// Global Styles
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1,
h2,
h3,
p,
a {
    font-family: 'Prompt', sans-serif;
    text-decoration: unset !important;
    margin-bottom: 0 !important;
}

// Color palette

.dark-blue {
    background-color: #263280;
}


.black {
    background-color: #333333;
}

.linear-red {
    background: linear-gradient(90deg, rgba(51,45,77,1) 20%, rgba(116,38,81,1) 50%, rgba(229,26,88,1) 100%);
}

.white {
    background-color: #ffffff;
}

.light-blue {
    background-color: #4684ff;
}

.text-blue {
    color: #1a2e83 ;
}

// Styles Layout

.header {
    display: flex;
    justify-content: center;

    .header-img {
        max-width: 150px;
    }
}

.main-background {
    background-color: #263280;
    padding-bottom: 80px;

    .content-main {
        max-width: 1070px;

        .main-title {
            color: #fff;
            font-weight: bold;
            font-size: 32px;
            letter-spacing: 0.1rem;
            padding-top: 60px;
        }

        .main-desc {
            padding-top: 40px;
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            padding-bottom: 30px;
        }
    }
    .card-container {
       
        .card-content {
            display: flex;
            border: 2px solid white;
            border-radius: 30px;
            max-width: 1050px;
            margin: 30px auto;
            padding: 40px;
            align-items: center;
            justify-content: space-between;
            height: 250px;
            -webkit-box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.5);
            box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.4);

            .card-info {
                width: 60%;

                .card-img {
                    max-width: 170px;
                }
                .card-desc {
                
                    font-weight: 300;
                    padding-top: 30px;
                }
            }

            .card-social {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                background: #fff;
                height: max-content;
                padding: 10px 15px;
                border-radius: 15px;
                -webkit-box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.3);
                -moz-box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.5);
                box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.3);

                &:hover {
                    opacity: 0.9;
                }

                .icon-img {
                    width: 40px;
                }

                .link-social {
                    color: #000;
                    font-weight: 600;
                    font-size: 20px;
                }
            }

        }


    }
}


.footer-background {
    background-color: #263280;
    padding-bottom: 50px;

    .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1070px;

        .footer-logo {
            max-width: 250px;
        }

        .footer-linkedin {
            max-width: 150px;
        }
    }


}

// Responsive

@media screen and (max-width:430px) {
    .main-background .content-main .main-title {
        text-align: center;
        font-size: 22px !important;
    }

    .main-title br {
        display: none;
    }

    .main-background .content-main .main-desc {
        text-align: center;
        font-size: 12px;
    }

    .main-background .card-container .card-content {
        height: 100%;
        display: grid;
    }

    .main-background .card-container .card-content .card-info {
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }

    .footer-background .footer-content {
        display: grid;
        justify-content: center;
        text-align: center;
    }

    .footer-background .footer-content .footer-logo {
        padding: 20px;
    }

    .footer-background .footer-content .footer-linkedin {
        margin: 0 auto;
    }

    .main-background .card-container .card-content {
        padding:20px;
        justify-content: center;
    }

    .main-background .content-main {
        padding: 20px;
    }

    .main-background .card-container .card-content .card-social {
        max-width: max-content;
        margin: 0 auto;
    }

    .main-background .card-container .card-content .card-info .card-desc {
        font-size: 14px;
        font-weight: 600;
    }
}